import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Chad Rieder | 404: Not Found" />
      <div className="max-inner">
        <div className="pad-inner inner-404">
          <div className="inner-404-wrap">
            <h1 className="hdg-2">Well, this is embarrassing!</h1>
            <p className="not-last">Either you mistyped, or this page is broken. Let’s get you back to <a href="/">home</a>.</p>
            <img className="olga" src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1440/q_70/v1535071564/olga-404.jpg" alt="Olga the Frenchie" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
